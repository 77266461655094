import { Controller } from "@hotwired/stimulus"
import EgressHelper from '@livekit/egress-sdk'
import { Room, RoomEvent, ConnectionCheck } from 'livekit-client';
import Cable from '../channels/cable';

export default class extends Controller {
  // Define Stimulus targets
  static targets = [
    "timer", "video", "points", "games", "sets", "setWrapper", "tiebreak", "isServe", 
    "scoreboard", "introduction", "nosignal", "adContainer", "adVideo", "messageBubble", "advertisementText"
  ]

  // Define Stimulus values
  static values = { 
    matchId: String,
    matchStatus: String,
    publisherName: String,
    team1Id: String,
    team2Id: String
  }

  connect() {
    console.log("Overlay controller connected")

    // Initialize LiveKit room
    this.initializeLiveKitRoom()

    // Start the connection and recording
    this.start()
    EgressHelper.startRecording()

    // Connect to WebSocket
    this.connectWebSocket()

    // Initialize and preload ads
    this.initializeAds()
  }

  // LiveKit room initialization
  initializeLiveKitRoom() {
    this.room = new Room({
      adaptiveStream: false,
    })

    EgressHelper.setRoom(this.room, {
      autoEnd: false,
    })

    this.room.on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed.bind(this))
    this.room.on(RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed.bind(this))
  }

  // Start LiveKit connection
  async start() {
    await this.room.connect(
      EgressHelper.getLiveKitURL(),
      EgressHelper.getAccessToken(),
      { 
        maxRetries: 10,
        websocketTimeout: 10000
      }
    )
  }

  // Handle subscribed tracks
  handleTrackSubscribed(track) {
    console.log("Track subscribed", track)
    if (track.kind === 'video') {
      this.attachVideoTrack(track)
    } else if (track.kind === 'audio') {
      this.attachAudioTrack(track)
    }
  }

  // Attach video track to DOM
  attachVideoTrack(track) {
    const videoElement = track.attach()
    videoElement.id = 'camera-feed'
    videoElement.width = 1920
    videoElement.height = 1080
    videoElement.controls = false
    if (this.hasVideoTarget) {
      this.videoTarget.appendChild(videoElement)
      this.nosignalTarget.classList.add('hidden')
    } else {
      console.error("Video target is not available")
    }
  }

  // Attach audio track to DOM
  attachAudioTrack(track) {
    this.audioElement = track.attach()
    if (this.hasVideoTarget) {
      this.videoTarget.appendChild(this.audioElement)
    } else {
      console.error("Video target is not available")
    }
  }

  // Handle unsubscribed tracks
  handleTrackUnsubscribed(track) {
    console.log("Track unsubscribed", track)
    if (track.kind === 'video') {
      this.nosignalTarget.classList.remove('hidden')
      this.videoTarget.querySelectorAll('video, audio').forEach(element => element.remove())
    }
  }

  // WebSocket connection
  connectWebSocket() {
    this.channel = Cable.subscribeTo("MatchChannel", {
      match_id: this.matchIdValue
    })

    this.channel.on("message", this.processEvent.bind(this))

    this.channel.on('connect', () => {
      console.log("Connected to MatchChannel")
      this.channel.perform('get_state')
    })
  }

  // Process incoming WebSocket events
  processEvent(data) {
    console.log("Event", data)

    switch(data.action) {
      case 'update_score':
        this.handleScoreUpdate(data)
        break
      case 'match_state':
        this.handleMatchState(data)
        break
      case 'tiebreak_game':
        this.showTiebreakGame()
        break
      case 'switch_sides':
        this.handleSwitchSides(data)
        break
      case 'finished':
        this.handleMatchFinished()
        break
    }
  }

  // Handle score update event
  handleScoreUpdate(data) {
    console.log("Updating score")
    this.updateScores(data.scores)
    if (this.scoreboardTarget.classList.contains('hidden')) {
      this.introductionTarget.classList.add('hidden')
      this.scoreboardTarget.classList.remove('hidden')
    }
    this.showRandomAdvertisement()
  }

  // Handle match state event
  handleMatchState(data) {
    console.log("Match state")
    if (data.status === 'scheduled') {
      this.resetScores()
    } else {
      this.updateScores(data.scores)
    }
    this.startTimer(data.started_at)
    if (data.is_tiebreak_game === true) {
      this.showTiebreakGame()
    }
  }

  // Handle switch sides event
  handleSwitchSides(data) {
    const hasTiebreakScore = data.scores.team1.sets.some(set => 'tiebreak_score' in set) || 
                             data.scores.team2.sets.some(set => 'tiebreak_score' in set);
    
    if (data.scores.team1.points === '0' && 
        data.scores.team2.points === '0' && 
        data.scores.team1.current_game !== 2 &&
        !hasTiebreakScore) {
      setTimeout(() => {
        this.playSwitchSidesAds()
      }, 5000)
    }
  }

  // Handle match finished event
  handleMatchFinished() {
    console.log("Match finished")
    setTimeout(() => {
      this.playFinishedAds()
    }, 7000)
  }

  // Reset scores to initial state
  resetScores() {
    const zeroScores = {
      team1: {
        id: this.team1IdValue,
        current_game: 0,
        current_set: 1,
        is_serve: false,
        points: "-",
        sets: [
          {
            games_won: 0,
            is_super_tiebreak: false,
            set_number: 1,
            set_won: false
          }
        ]
      },
      team2: {
        id: this.team2IdValue,
        current_game: 0,
        current_set: 1,
        is_serve: false,
        points: "-",
        sets: [
          {
            games_won: 0,
            is_super_tiebreak: false,
            set_number: 1,
            set_won: false
          }
        ]
      }
    }
    this.updateScores(zeroScores)
  }

  // Update scores in the UI
  updateScores(scores) {
    Object.entries(scores).forEach(([teamKey, scoreDetails]) => {
      this.updateScore("points", scoreDetails.id, scoreDetails.points)
      this.updateScore("games", scoreDetails.id, scoreDetails.current_game)
      this.updateSets(scoreDetails.id, scoreDetails.sets)
      this.updateIsServe(scoreDetails.id, scoreDetails.is_serve)
    })
  }

  // Update a specific score type (points or games)
  updateScore(scoreType, teamId, score) {
    console.log('Updating score', scoreType, teamId, score)
    const scoreTargets = this[scoreType + "Targets"]
    const target = Array.from(scoreTargets).find(target => target.dataset.teamId === teamId)
    if (target) {
      target.textContent = score
    }
  }

  // Update sets in the UI
  updateSets(teamId, sets) {
    const setWrapperTarget = this.setWrapperTargets.find(target => target.dataset.teamId === teamId)
    if (!setWrapperTarget) return

    // Ensure correct number of set divs
    while (setWrapperTarget.children.length < sets.length) {
      const newSetDiv = this.createSetDiv(teamId, setWrapperTarget.children.length + 1)
      setWrapperTarget.appendChild(newSetDiv)
    }

    sets.forEach((set, index) => {
      const setDiv = setWrapperTarget.children[index]
      const scoreElement = setDiv.querySelector(`[data-overlay-target="sets"][data-team-id="${teamId}"][data-set-number="${set.set_number}"]`)
      const tiebreakElement = setDiv.querySelector(`[data-overlay-target="tiebreak"][data-team-id="${teamId}"][data-set-number="${set.set_number}"]`)

      if (scoreElement) {
        scoreElement.textContent = set.games_won
        scoreElement.classList.toggle("set-won", set.set_won)
      }

      if (tiebreakElement && set.tiebreak_score) {
        tiebreakElement.textContent = set.tiebreak_score
      }

      // Update background for current set
      setDiv.classList.toggle("text-black", index === sets.length - 1)
      setDiv.classList.toggle("bg-gray-100", index !== sets.length - 1)
    })
  }

  // Create a new set div
  createSetDiv(teamId, setNumber) {
    const setDiv = document.createElement('div')
    setDiv.classList.add('relative', 'w-8', 'flex', 'items-center', 'justify-center', 'text-2xl', 'font-bold', 'text-carbon-700')
    
    const scoreElement = document.createElement('div')
    scoreElement.setAttribute('data-overlay-target', 'sets')
    scoreElement.setAttribute('data-team-id', teamId)
    scoreElement.setAttribute('data-set-number', setNumber)
    scoreElement.textContent = '0'
    
    const tiebreakElement = document.createElement('span')
    tiebreakElement.setAttribute('data-overlay-target', 'tiebreak')
    tiebreakElement.setAttribute('data-team-id', teamId)
    tiebreakElement.setAttribute('data-set-number', setNumber)
    tiebreakElement.classList.add('absolute', 'right-1', 'top-1', 'text-[8px]', 'font-medium')
    
    setDiv.appendChild(scoreElement)
    setDiv.appendChild(tiebreakElement)
    
    return setDiv
  }

  // Update serve indicator
  updateIsServe(teamId, isServe) {
    const isServeTarget = this.isServeTargets.find(target => target.dataset.teamId === teamId)
    if (isServeTarget) {
      isServeTarget.classList.toggle("hidden", !isServe)
    }
  }

  // Show tiebreak game
  showTiebreakGame() {
    console.log("Tiebreak game started")
    // Implementation for visually representing a tiebreak game
  }

  // Start the match timer
  startTimer(startedAt = new Date()) {
    this.startTime = new Date(startedAt).getTime()
    this.stopTimer() // Clear any existing timer
    this.timer = setInterval(() => {
      this.updateTimer()
    }, 1000)
  }

  // Stop the match timer
  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  // Update the timer display
  updateTimer() {
    const now = new Date().getTime()
    const timeElapsed = now - this.startTime
    const hours = Math.floor((timeElapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeElapsed % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeElapsed % (1000 * 60)) / 1000)

    const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    ].join(':')

    if (this.hasTimerTarget) {
      this.timerTarget.textContent = formattedTime
    }
  }

  // Initialize ads
  initializeAds() {
    this.adUrls = [
      "https://ads.baseline.vn/vtf_championship_ad.mp4",
      "https://ads.baseline.vn/baseline_app_ad.mp4",
      "https://ads.baseline.vn/ocany-no-sound-ad.mp4"
    ]

    this.switchSideAdUrls = [
      "https://ads.baseline.vn/baseline_app_ad.mp4"
    ]

    this.finishedAdUrls = [
      "https://ads.baseline.vn/vtf_championship_ad.mp4",
      "https://ads.baseline.vn/baseline_app_ad.mp4"
    ]

    this.preloadedAds = new Map()
    this.currentAdIndex = 0
    this.adsPlayed = false

    this.preloadAllAds().then(() => {
      this.loadAndPlayFirstAd()
    })
  }

  // Preload all ads
  async preloadAllAds() {
    for (let url of this.adUrls) {
      await this.preloadSingleAd(url)
    }
  }

  // Preload a single ad
  preloadSingleAd(url) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.preload = 'auto'
      video.src = url
      video.oncanplaythrough = () => {
        this.preloadedAds.set(url, video)
        resolve()
      }
      video.onerror = reject
    })
  }

  // Load and play the first ad
  loadAndPlayFirstAd() {
    if (this.adsPlayed) return
    if (this.hasAdContainerTarget && this.hasAdVideoTarget) {
      this.adContainerTarget.classList.remove('hidden')
      this.playNextAd()
      this.adsPlayed = true
    }
  }

  // Play the next ad in sequence
  playNextAd() {
    if (this.currentAdIndex >= this.adUrls.length) {
      this.finishAdSequence()
      return
    }

    const currentAdUrl = this.adUrls[this.currentAdIndex]
    const nextAdUrl = this.adUrls[(this.currentAdIndex + 1) % this.adUrls.length]

    const currentAd = this.preloadedAds.get(currentAdUrl)
    if (!currentAd) {
      console.error("Current ad not preloaded:", currentAdUrl)
      this.currentAdIndex++
      this.playNextAd()
      return
    }

    if (this.audioElement) {
      this.audioElement.muted = true
    }

    this.adVideoTarget.src = currentAd.src
    this.adVideoTarget.load()

    this.adVideoTarget.oncanplay = () => {
      this.fadeInAd(() => {
        this.adVideoTarget.play()
      })
    }

    this.adVideoTarget.onended = () => {
      this.fadeOutAd(() => {
        this.currentAdIndex++
        this.playNextAd()
      })
    }

    // Preload the next ad
    this.preloadSingleAd(nextAdUrl)
  }

  // Fade in the ad
  fadeInAd(callback) {
    this.adVideoTarget.style.opacity = 0
    this.adVideoTarget.style.transition = 'opacity 0.5s'
    setTimeout(() => {
      this.adVideoTarget.style.opacity = 1
      if (callback) callback()
    }, 50)
  }

  // Fade out the ad
  fadeOutAd(callback) {
    this.adVideoTarget.style.opacity = 1
    this.adVideoTarget.style.transition = 'opacity 0.5s'
    setTimeout(() => {
      this.adVideoTarget.style.opacity = 0
      setTimeout(() => {
        if (callback) callback()
      }, 500)
    }, 50)
  }

  // Finish the ad sequence
  finishAdSequence() {
    this.adContainerTarget.classList.add('hidden')
    
    // Unmute audio
    if (this.audioElement) {
      this.audioElement.muted = false
    }
  }

  // Play ads for switching sides
  playSwitchSidesAds() {
    if (this.hasAdContainerTarget && this.hasAdVideoTarget && this.switchSideAdUrls.length > 0) {
      this.adContainerTarget.classList.remove('hidden')
      this.adUrls = this.switchSideAdUrls
      this.currentAdIndex = 0
      this.adsPlayed = false
      
      if (this.adUrls.length === 1) {
        // If there's only one ad, play it directly
        this.preloadSingleAd(this.adUrls[0]).then(() => {
          this.playNextAd()
        })
      } else {
        // If there are multiple ads, preload all and then play
        this.preloadAllAds().then(() => {
          this.playNextAd()
        })
      }
    }
  }

  // Play ads for finished match
  playFinishedAds() {
    if (this.hasAdContainerTarget && this.hasAdVideoTarget) {
      this.adContainerTarget.classList.remove('hidden')
      this.currentAdIndex = 0
      this.adUrls = this.finishedAdUrls
      this.adsPlayed = false
      this.preloadAllAds().then(() => {
        this.playNextAd()
      })
    }
  }

  // Cleanup when disconnecting
  disconnect() {
    this.stopTimer()
    if (this.channel) {
      this.channel.unsubscribe()
    }
  }

  // Show random advertisement
  showRandomAdvertisement() {
    // 20% chance to show the message
    if (Math.random() < 0.10) {
      const ads = [
        "Chào mừng các bạn đến với phần chat! 👋",
        "Đoán xem ai sẽ thắng nào? Hóng bình luận của mọi người 👇",
        "Hãy cổ vũ hết mình cho tay vợt yêu thích của bạn nhé 🙌"
      ];

      // Select a random ad
      const randomAd = ads[Math.floor(Math.random() * ads.length)];
      this.advertisementTextTarget.textContent = randomAd;

      // Fade in the message bubble
      this.messageBubbleTarget.classList.remove("hidden");
      this.messageBubbleTarget.style.opacity = 0;
      let opacity = 0;
      const fadeIn = setInterval(() => {
        if (opacity < 1) {
          opacity += 0.1;
          this.messageBubbleTarget.style.opacity = opacity;
        } else {
          clearInterval(fadeIn);
        }
      }, 50);

      // Fade out after 10 seconds
      setTimeout(() => {
        let opacity = 1;
        const fadeOut = setInterval(() => {
          if (opacity > 0) {
            opacity -= 0.1;
            this.messageBubbleTarget.style.opacity = opacity;
          } else {
            clearInterval(fadeOut);
            this.messageBubbleTarget.classList.add("hidden");
          }
        }, 50);
      }, 5000);
    }
  }
}