import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log('connected header');
    var gif = document.getElementById('header-animation');
    gif.classList.add('fade-out');
    gif.addEventListener('ended', function() {
        gif.classList.add('fade-out');
        gif.loop = false;
    }, false);
  }
}
