import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "logo"]

  connect() {
    this.applyTheme();
  }

  applyTheme() {
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }
}
