import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "frame"]
  
    search() {
      const query = this.inputTarget.value
      const frame = this.frameTarget
      const srcBase = frame.getAttribute('data-src-base')
      const src = `${srcBase}?players=${encodeURIComponent(query)}`
      frame.src = src
    }
}