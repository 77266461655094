// Entry point for the build script in your package.json

import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({ apiKey: '08b602053ebf12c7f7747aecb445bb8c' })
BugsnagPerformance.start({ apiKey: '08b602053ebf12c7f7747aecb445bb8c' })

import "@hotwired/turbo-rails"

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

// Import external modules
import "flowbite/dist/flowbite.turbo.js";
// import "smartbanner.js"

import LocalTime from "local-time"
LocalTime.start()

// Make accessible for Electron and Mobile adapters
// window.Rails = Rails

// Stimulus controllers
import "./controllers"
import "current.js"

// Custom Turbo Confirmation Modal
Turbo.setConfirmMethod((message, element) => {
    console.log(message, element)
    let dialog = document.getElementById("turbo-confirm")
    dialog.querySelector("p").textContent = message
    dialog.showModal()
  
    return new Promise((resolve, reject) => {
      dialog.addEventListener("close", () => {
        resolve(dialog.returnValue == "confirm")
      }, { once: true })
    })
})

