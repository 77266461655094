import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "output"]
  static values = { showIf: String }
  
  connect() {
    this.toggle();
  }

  toggle() {
    const checkedInput = this.inputTargets.find(input => input.checked || input.tagName === "SELECT");
    const valueToCheck = checkedInput && checkedInput.tagName === "SELECT" ? checkedInput.value : (checkedInput ? checkedInput.value : null);

    console.log('showhide debug',this.showIfValue, checkedInput?.tagName, valueToCheck);

    this.outputTargets.forEach((outputTarget) => {
      const showhideValues = outputTarget.dataset.showhideValue.split(',');

      outputTarget.hidden = !showhideValues.includes(valueToCheck);
    });
  }

  // toggle() {
  //   console.log('toggle');
  //   const checkedInput = this.inputTargets.find(input => input.checked);
  //   if (checkedInput && checkedInput.value === this.showIfValue) {
  //     this.outputTarget.hidden = false;
  //   } else {
  //     this.outputTarget.hidden = true;
  //     this.outputTargets.forEach(input => input.value = '');
  //   }
  // }

}