import { Controller } from "@hotwired/stimulus"
import EgressHelper from '@livekit/egress-sdk'
import { 
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  VideoPresets,
  Track,
  LocalTrackPublication,
  LocalParticipant,
  createLocalTracks
} from 'livekit-client';
import Cable from '../channels/cable';

export default class extends Controller {
  static targets = [
    "timer", "video", "points", "games", "sets", "setWrapper", "tiebreak", "isServe", 
    "scoreboard", "introduction", "nosignal", "adContainer", "adVideo", "streamerVideo", "baseline", "sponsors", 
    "overlaysIcon", "soundIcon", "microphoneIcon", "videoIcon"
  ]
  static values = { 
    matchStatus: String,
    token: String, 
    roomName: String,
    host: { type: String, default: "basestream.baseline.vn" },
    matchId: String,
    matchStatus: String,
    publisherName: String,
    action: String
  }

  connect() {
    console.log("Overlay controller connected")

    this.room = new Room({
      adaptiveStream: false,
      videoCaptureDefaults: {
        resolution: VideoPresets.h1080.resolution,
      },
    })

    if (this.matchStatusValue !== 'scheduled') {
      this.room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => this.handleTrackSubscribed(track, publication, participant));
      this.room.on(RoomEvent.TrackUnsubscribed, (track, publication, participant) => this.handleTrackUnsubscribed(track, publication, participant));

      this.connectToRoom(); // Connect to the room
    }

    this.connectWebSocket(); // Connect to the scoring websocket

    if (this.actionValue === 'stream' && this.matchStatusValue === 'scheduled') {
      this.loadStreamerMedia();
    }

    this.overlaysManuallyHidden = false;
    this.setupCursorHiding();
  }

  async loadStreamerMedia() {
    const localTracks = await createLocalTracks({
      audio: true,
      video: true,
    });

    if (this.actionValue === 'stream') {
      try {
        const videoTrack = localTracks.find(track => track.kind === 'video');
        if (!videoTrack) {
          throw new Error('No video track found');
        }

        const videoElement = videoTrack.attach();
        videoElement.classList.add('w-full', 'h-full', 'object-cover', 'object-center', 'rounded');
        videoElement.autoplay = true;
        videoElement.muted = false;

        this.streamerVideoTarget.innerHTML = '';
        this.streamerVideoTarget.appendChild(videoElement);

        if (this.matchStatusValue !== 'scheduled') {
          await this.room.localParticipant.publishTrack(videoTrack);
        }
      } catch (error) {
        console.error("There was an error starting the streamer media", error);
      }
    }
  }

  async checkConnection() {
    console.log("Checking connection");

    const connectionCheck = new ConnectionCheck(
      EgressHelper.getLiveKitURL(),
      EgressHelper.getAccessToken(),
      {
        maxRetries: 10,
        timeout: 10000
      }
    );

    try {
      await connectionCheck.checkWebRTC();
      await connectionCheck.checkWebsocket();
      await connectionCheck.checkTURN();
      await connectionCheck.checkReconnect();

      if (connectionCheck.isSuccess()) {
        console.log("All connection checks passed");
      } else {
        console.error("Some connection checks failed");
      }

      console.log("Connection check results:", connectionCheck.getResults());
    } catch (error) {
      console.error("Error during connection check:", error);
    }
  }

  async connectToRoom() {
    await this.room.connect(
      `wss://${this.hostValue}`,
      this.tokenValue,
      { 
        maxRetries: 10,
        websocketTimeout: 10000
      }
    )
  }

  handleTrackSubscribed(track) {
    console.log("Track subscribed", track);
    if (track.kind === 'video') {

        const videoElement = track.attach();
        videoElement.id = 'camera-feed';
        videoElement.width = 1920;
        videoElement.height = 1080;
        videoElement.controls = false;
        if (this.hasVideoTarget) {
            this.videoTarget.appendChild(videoElement);
            this.nosignalTarget.classList.add('hidden');
        } else {
            console.error("Video target is not available");
        }
    } else if (track.kind === 'audio') {
        const audioElement = track.attach();
        if (this.hasVideoTarget) {
            this.videoTarget.appendChild(audioElement);
        } else {
            console.error("Video target is not available");
        }
    }
  }

  handleTrackUnsubscribed(track) {
    console.log("Track unsubscribed", track);
    if (track.kind === 'video') {
      this.nosignalTarget.classList.remove('hidden');
      this.videoTarget.querySelectorAll('video, audio').forEach(element => element.remove());
    }
  }

  connectWebSocket() {
    this.channel = Cable.subscribeTo("MatchChannel", {
      match_id: this.matchIdValue
    });

    this.channel.on("message", (data) => {
      this.processEvent(data);
    });

    this.channel.on('connect', ev => {
        console.log("Connected to MatchChannel");
        this.channel.perform('get_state');
    })
  }

  processEvent(data) {
    console.log("Event", data);

    switch(data.action) {
      case 'update_score':
        console.log("Updating score");
        this.updateScores(data.scores);
        if (!this.overlaysManuallyHidden && this.scoreboardTarget.classList.contains('hidden')) {
          this.introductionTarget.classList.add('hidden');
          this.scoreboardTarget.classList.remove('hidden');
        }
        break;
      case 'match_state':
        console.log("Match state");
        if (data.status === 'ongoing') {
          this.updateScores(data.scores);
          this.startTimer(data.started_at);
          if (data.is_tiebreak_game === true) {
            this.showTiebreakGame();
          }
        }
        break;
      case 'tiebreak_game':
        console.log("Tiebreak game");
        this.showTiebreakGame();
        break;
      case 'start':
        console.log("Match started");
        if (this.matchStatusValue === 'scheduled') {
          window.location.reload();
        }
        break;
    }
  }

  updateScores(scores) {
    Object.entries(scores).forEach(([teamKey, scoreDetails]) => {
      this.updateScore("points", scoreDetails.id, scoreDetails.points);
      this.updateScore("games", scoreDetails.id, scoreDetails.current_game);
      this.updateSets(scoreDetails.id, scoreDetails.sets);
      this.updateIsServe(scoreDetails.id, scoreDetails.is_serve);
    });
  }

  updateScore(scoreType, teamId, score) {
    console.log('Updating score', scoreType, teamId, score);
    const scoreTargets = this[scoreType + "Targets"];
    const target = Array.from(scoreTargets).find(target => target.dataset.teamId === teamId);
    if (target) {
      target.textContent = score;
    }
  }

  updateSets(teamId, sets) {
    const setWrapperTarget = this.setWrapperTargets.find(target => target.dataset.teamId === teamId);
    if (!setWrapperTarget) return;

    while (setWrapperTarget.children.length < sets.length) {
      const newSetDiv = this.createSetDiv(teamId, setWrapperTarget.children.length + 1);
      setWrapperTarget.appendChild(newSetDiv);
    }

    sets.forEach((set, index) => {
      const setDiv = setWrapperTarget.children[index];
      const scoreElement = setDiv.querySelector(`[data-stream-target="sets"][data-team-id="${teamId}"][data-set-number="${set.set_number}"]`);
      const tiebreakElement = setDiv.querySelector(`[data-stream-target="tiebreak"][data-team-id="${teamId}"][data-set-number="${set.set_number}"]`);

      if (scoreElement) {
        scoreElement.textContent = set.games_won;
        if (set.set_won) {
          scoreElement.classList.add("set-won");
        } else {
          scoreElement.classList.remove("set-won");
        }
      }

      if (tiebreakElement && set.tiebreak_score) {
        tiebreakElement.textContent = set.tiebreak_score;
      }

      if (index === sets.length - 1) {
        setDiv.classList.add("text-black");
        setDiv.classList.remove("bg-gray-100");
      } else {
        setDiv.classList.remove("text-black");
        setDiv.classList.add("bg-gray-100");
      }
    });
  }

  createSetDiv(teamId, setNumber) {
    const setDiv = document.createElement('div');
    setDiv.classList.add('relative', 'w-8', 'flex', 'items-center', 'justify-center', 'text-lg', 'font-semibold', 'text-carbon-700');
    
    const scoreElement = document.createElement('div');
    scoreElement.setAttribute('data-stream-target', 'sets');
    scoreElement.setAttribute('data-team-id', teamId);
    scoreElement.setAttribute('data-set-number', setNumber);
    scoreElement.textContent = '0';
    
    const tiebreakElement = document.createElement('span');
    tiebreakElement.setAttribute('data-stream-target', 'tiebreak');
    tiebreakElement.setAttribute('data-team-id', teamId);
    tiebreakElement.setAttribute('data-set-number', setNumber);
    tiebreakElement.classList.add('absolute', 'right-1', 'top-1', 'text-[8px]', 'font-medium');
    
    setDiv.appendChild(scoreElement);
    setDiv.appendChild(tiebreakElement);
    
    return setDiv;
  }

  updateIsServe(teamId, isServe) {
    const isServeTarget = this.isServeTargets.find(target => target.dataset.teamId === teamId);
    if (isServeTarget) {
      isServeTarget.classList.toggle("hidden", !isServe);
    }
  }

  showTiebreakGame() {
    console.log("Tiebreak game started");
  }

  startTimer(startedAt = new Date()) {
    this.startTime = new Date(startedAt).getTime();
    this.stopTimer();
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  updateTimer() {
    const now = new Date().getTime();
    const timeElapsed = now - this.startTime;
    const hours = Math.floor((timeElapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeElapsed % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeElapsed % (1000 * 60)) / 1000);

    const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    ].join(':');

    if (this.hasTimerTarget) {
      this.timerTarget.textContent = formattedTime;
    }
  }

  toggleOverlays(event) {
    event.preventDefault();

    this.overlaysManuallyHidden = !this.overlaysManuallyHidden;

    if (this.hasIntroductionTarget && this.matchStatusValue == 'scheduled') {
      this.introductionTarget.classList.toggle('hidden');
    }
    if (this.hasScoreboardTarget && this.matchStatusValue == 'ongoing') {
      this.scoreboardTarget.classList.toggle('hidden');
    }
    if (this.hasBaselineTarget) {
      this.baselineTarget.classList.toggle('hidden');
    }
    if (this.hasSponsorsTarget) {
      this.sponsorsTarget.classList.toggle('hidden');
    }
    
    const overlaysIcon = this.overlaysIconTarget;
    if (overlaysIcon) {
      overlaysIcon.classList.toggle('fa-eye-slash');
      overlaysIcon.classList.toggle('fa-eye');
    }
  }

  toggleFullscreen(event) {
    event.preventDefault();
    console.log("Toggle fullscreen");
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  toggleSound(event) {
    event.preventDefault();
    console.log("Mute sound");
    const audioElement = this.videoTarget.querySelector('audio');
    if (audioElement) {
      audioElement.muted = !audioElement.muted;
    }

    const soundIcon = this.soundIconTarget;
    if (soundIcon) {
      soundIcon.classList.toggle('fa-volume-up');
      soundIcon.classList.toggle('fa-volume-mute');
    }
  }

  setupCursorHiding() {
    let timeout;
    const hideCursor = () => {
      document.body.style.cursor = 'none';
    };
    const showCursor = () => {
      document.body.style.cursor = 'auto';
      clearTimeout(timeout);
      timeout = setTimeout(hideCursor, 3000);
    };

    document.addEventListener('mousemove', showCursor);
    document.addEventListener('mousedown', showCursor);
    document.addEventListener('keypress', showCursor);
    
    // Initial setup
    timeout = setTimeout(hideCursor, 3000);
  }

  disconnect() {
    this.stopTimer();
    if (this.channel) {
      this.channel.unsubscribe();
    }
    if (this.actionValue !== 'screen_capture' && this.hasStreamerVideoTarget && this.streamerVideoTarget.srcObject) {
      this.streamerVideoTarget.srcObject.getTracks().forEach(track => track.stop());
    }
  }
}