import { Application } from "@hotwired/stimulus"
import Lightbox from '@stimulus-components/lightbox'

const application = Application.start()

import HwComboboxController from "@josefarias/hotwire_combobox"
application.register("hw-combobox", HwComboboxController)
application.register('lightbox', Lightbox)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
