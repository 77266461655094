import { Controller }   from "@hotwired/stimulus"
import { Current } from "current.js"
import {   Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  VideoPresets,
  Room,
  RoomEvent, } from 'livekit-client';

export default class extends Controller {
     static targets = [ "video", "loader" ]
     static values = { token: String, roomName: String, location: { type: String, default: "feed" }, host: { type: String, default: "livekit.baseline.vn" } }

     connect() {
        this.connectToRoom();
     }

     async connectToRoom() {
        const room = new Room({
            adaptiveStream: true,
            dynacast: true,
            videoCaptureDefaults: {
                resolution: VideoPresets.h720.resolution,
            },
        });

        // Set the host dynamically
        const host = `wss://${this.hostValue}`;

        room.prepareConnection(host, this.tokenValue);

        room
            .on(RoomEvent.TrackSubscribed, (track, publication, participant) => this.handleTrackSubscribed(track, publication, participant))

        await room.connect(host, this.tokenValue);
        console.log('connected to room', room.name);
     }

     handleTrackSubscribed(track, publication, participant) {
        console.log("Track subscribed", track); // Helpful for debugging
        this.loaderTarget.classList.add('hidden');
        if (track.kind === 'video') {
            const videoElement = track.attach();
            videoElement.width = 1920; // Width for 1080p resolution
            videoElement.height = 1080; // Height for 1080p resolution
            videoElement.controls = true; // Show controls for the video element
            if (this.hasVideoTarget) {
                this.videoTarget.prepend(videoElement);
            } else {
                console.error("Feed target is not available");
            }
        } else if (track.kind === 'audio') {
            const audioElement = track.attach();
            if (this.locationValue === "match") {
                if (this.hasVideoTarget) {
                    this.videoTarget.prepend(audioElement);
                } else {
                    console.error("Feed target is not available");
                }
            }
        }
     }
}

