import { Controller } from "@hotwired/stimulus"
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; // Import the QuillDeltaToHtmlConverter
import Quill from 'quill';

export default class extends Controller {
    static targets = [ "editor", "output", "content" ]
    static values = { 
        placeholder: String,
        readOnly: Boolean,
    }
    
    connect() {
        if (this.readOnlyValue) {            
            var content = JSON.parse(this.outputTarget.innerHTML);            

            var cfg = {};
            var converter = new QuillDeltaToHtmlConverter(content.ops, cfg);

            // Get the HTML content
            var html = converter.convert();
            this.contentTarget.innerHTML = html;
        } else {
            // Options for the Quill editor
            const options = {
                theme: 'snow',
                placeholder: this.placeholderValue || 'Write...', // Default placeholder if none specified
                readOnly: this.readOnlyValue || false, // Default readOnly state if none specified
                modules: {
                    toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons           // custom button values
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ['clean'],                                         // remove formatting button
                        ['link', 'image', 'video']                         // link and image, video
                    ]
                }
            };

             // Create the Quill instance
            this.editor = new Quill(this.editorTarget, options);

            // Set initial content
            if (this.outputTarget.value) {
                var content = JSON.parse(this.outputTarget.value);
                // var parsedObject = JSON.parse(content);
                this.editor.setContents(content.ops, 'api');
            }

            // Listen for changes and update the hidden field
            this.editor.on('editor-change', () => {
                this.syncContent();
            });
        }
    }

    syncContent() {
        // Update the hidden field with the JSON representation of the editor's content
        this.outputTarget.value = JSON.stringify(this.editor.getContents());
    }

}
