import { Controller } from "@hotwired/stimulus"

export default class extends Controller {    
    static values = {
        refreshInterval: { type: Number, default: 120000 }
    }

    connect() {
        this.refreshPageEveryTwoMinutes();
    }

    refreshPageEveryTwoMinutes() {
        setInterval(() => {
            window.location.reload();
        }, this.refreshIntervalValue); // 120000 milliseconds = 2 minutes
    }
}

