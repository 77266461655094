import { Controller } from "@hotwired/stimulus"
import { useVisibility } from "stimulus-use"; 

export default class extends Controller {
  static targets = [ "back", "feed" ]

  connect() {
    // this.toggleBackButtonVisibility();
    useVisibility(this);
  }

  disconnect() {
    this.feedTarget.classList.add("hidden");
  }

  goBack() {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = '/';
    }
  }
}