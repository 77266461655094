import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import TomSelect from 'tom-select';

export default class extends Controller {
    static values = { 
        max: Number,
        selected: String,
        search: { type: Boolean, default: false },
        url: String,
    }

    connect() {
        // Start with base config
        let config = {
            create: false,
            maxItems: this.maxValue || 1,
            valueField: 'id',
            labelField: this.searchValue ? 'full_name' : 'name', // Adjust labelField based on searchValue
        };
    
        // If search is enabled, extend the config with additional options
        if (this.searchValue) {
            Object.assign(config, {
                // searchField: 'vtid',
                load: async (query, callback) => {
                    if (!query.length) return callback();
                    try {
                        const response = await get(`${this.urlValue}?q=${encodeURIComponent(query)}`, {
                            responseKind: "json"
                        });
                        if (response.ok) {
                            const json = await response.text;
                            console.log("Results:", json);
                            console.log("Results:", JSON.parse(json));
                            callback(JSON.parse(json));
                        } else {
                            callback();
                        }
                    } catch (error) {
                        console.error("Failed to load options:", error);
                        callback();
                    }
                },
                render: {
                    option: function(data, escape) {
                        return '<div>' +
                                    '<p class="title font-semibold">' + escape(data.full_name) + '</p>' +
                                    '<p class="url font-light">' + escape(data.vtid) + '</p>' +
                                '</div>';
                    },
                    item: function(data, escape) {
                        return '<div title="' + escape(data.vtid) + '">' + escape(data.full_name) + '</div>';
                    }
                }
            });
        }
    
        let select_field = new TomSelect(this.element, config);
    
        // Optionally, pre-select an item if 'selected' value is provided
        if (this.selectedValue) {
            select_field.setValue(this.selectedValue);
        }
    }    
}
