import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"];
  static values = { 
    url: String,
    title: String,
    message: String 
  };

  share(event) {
    if (!navigator.share) {
        // If not available, do nothing and let the default Turbo Frame action proceed
        console.log("Web Share API not supported.");
        return;
    }
  
    // Prevent the default link action (which is to open the Turbo Frame modal)
    event.preventDefault();

    navigator.share({
        title: this.titleValue || document.title,
        url: this.urlValue || event.currentTarget.href,
        text: this.messageValue || ""
    }).then(() => {
        console.log("Thanks for sharing!");
    }).catch((error) => {
        console.log('Error sharing:', error);
    });
  }
}
