import { Controller } from "@hotwired/stimulus"
import Hls from "hls.js"

export default class extends Controller {
    static targets = ["video", "loader", "container"]
    static values = { url: String, matchStatus: String }
    // hls = null;

    connect() {
        if (Hls.isSupported()) {
            this.hls = new Hls({
                maxBufferSize: 60 * 1000 * 1000,
                maxBufferLength: 60,
                maxMaxBufferLength: 600,
                liveSyncDurationCount: 7,
                liveMaxLatencyDurationCount: 10,
                highBufferWatchdogPeriod: 120,
                nudgeMaxRetry: 10,
                enableWorker: true,
                capLevelToPlayerSize: false,
                defaultAudioCodec: undefined,
                liveDurationInfinity: this.matchStatusValue === "ongoing" ? true : false
            });
            this.hls.loadSource(this.urlValue);
            this.hls.attachMedia(this.videoTarget);
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                this.videoTarget.setAttribute('width', '1920'); // Set width for 1080p
                this.videoTarget.setAttribute('height', '1080'); // Set height for 1080p
                if (this.matchStatusValue === "finished") {
                    this.videoTarget.controls = true;
                    this.hls.startLoad(0); // Start the playlist from the first segment
                } else if (this.matchStatusValue === "ongoing") {
                    this.videoTarget.controls = true;
                }
                this.videoTarget.play();
                this.loaderTarget.classList.add('hidden');
                this.containerTarget.classList.remove('hidden');
            });
        } else if (this.videoTarget.canPlayType('application/vnd.apple.mpegurl')) {
        
            this.videoTarget.src = this.urlValue;
            this.videoTarget.setAttribute('width', '1920'); // Set width for 1080p
            this.videoTarget.setAttribute('height', '1080'); // Set height for 1080p
            this.videoTarget.setAttribute('autoplay', ''); // Enable autoplay
            this.videoTarget.muted = true; // Start muted
            this.videoTarget.addEventListener('loadedmetadata', () => {
                if (this.matchStatusValue === "finished") {
                    this.videoTarget.controls = true;
                    this.videoTarget.currentTime = 0; // Ensures playback starts from the beginning
                } else if (this.matchStatusValue === "ongoing") {
                    this.videoTarget.controls = true;
                }
                this.loaderTarget.classList.add('hidden');
                this.containerTarget.classList.remove('hidden');
            });

        }
    }

    disconnect() {
        if (this.hls) {
            this.hls.destroy();
        }
        if (this.videoTarget) {
            this.videoTarget.pause();
            this.videoTarget.removeAttribute('src'); // empty source
            this.videoTarget.load();
        }
    }
}

