import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["drawer", "toggleButton"]

    connect() {
        // Ensure the drawer is initially hidden
        if (!this.drawerTarget.classList.contains("translate-x-full")) {
            this.drawerTarget.classList.add("translate-x-full");
        }
    }

    toggle() {
        if (this.hasDrawerTarget) {
            this.drawerTarget.classList.toggle("translate-x-full");
            this.drawerTarget.classList.toggle("transform-none");
            document.body.classList.toggle("overflow-hidden");

            // Toggle the backdrop for the drawer
            let backdrop = document.querySelector('[drawer-backdrop]');
            if (!backdrop) {
                backdrop = document.createElement('div');
                backdrop.setAttribute('drawer-backdrop', '');
                backdrop.className = 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30';
                backdrop.addEventListener('click', () => this.toggle());
                document.body.appendChild(backdrop);
            } else {
                backdrop.remove();
            }
        }
    }
}
