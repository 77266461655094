import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "output"];

    connect() {
        this.convertRank();
    }
  
    convertRank() {
      const oldRank = this.inputTarget.value;
      const newRank = this.calculateModernRank(oldRank);
      this.outputTarget.value = newRank.toFixed(4);
    }
  
    calculateModernRank(oldRank) {
      const classicMin = 600, classicMax = 1200;
      const modernMin = 6.9999, modernMax = 1.0000;
  
      // Ensure oldRank does not go below classicMin for calculation
      const effectiveOldRank = Math.max(oldRank, classicMin);
      const normalizedRank = (effectiveOldRank - classicMin) / (classicMax - classicMin);
      return modernMin - (modernMin - modernMax) * normalizedRank;
    }
}