import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preview"];

  connect() {
    console.log('Preview controller connected');
  }

  selected(event) {
    console.log('Selected file:', event.currentTarget.files[0]);

    const files = event.currentTarget.files;
    let imageHTML = '';

    // Logic for handling a single file
    const [file] = files;
    if (file) {
        this.previewTarget.src = URL.createObjectURL(file);
    }
  }

}
