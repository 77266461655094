import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log("MODAL CONTROLLER CONNECTED")
  }

  close(e) {
    if (!(e instanceof CustomEvent)) {
      this.hideModal();
    }
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  submitForm(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.element.querySelector('form').submit();
    }
  }

  hideModal() {
    this.element.remove()
  }
}
