// cable_manager.js
import { createCable } from '@anycable/web';
import { Current } from "current.js"

class CableManager {
  constructor() {
    let cableUrl;
    switch (Current.environment) {
      case 'production':
        cableUrl = "wss://baseline.vn/cable";
        break;
      case 'staging':
        cableUrl = "wss://dev.baseline.vn/cable";
        break;
      case 'development':
        cableUrl = "ws://localhost:8080/cable";
        break;
      default:
        cableUrl = "ws://localhost:8080/cable"; // Default to development if environment is not set
    }
    this.cable = createCable(cableUrl);
    this.channels = {};
  }

  subscribeTo(channelName, params) {
    const channelKey = `${channelName}_${params.match_id}`;
    if (!this.channels[channelKey]) {
      this.channels[channelKey] = this.cable.subscribeTo(channelName, params);
    }
    return this.channels[channelKey];
  }
}

export default new CableManager();