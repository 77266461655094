// OTP Controller
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["otpInput", "hiddenOtpField"];

  connect() {
    this.initializeInputListeners();
  }

  initializeInputListeners() {
    this.otpInputTargets.forEach((input, index) => {
      input.addEventListener("input", () => {
        this.handleInput(input, index);
      });

      input.addEventListener("keydown", (event) => {
        this.handleBackspace(event, input, index);
      });

      input.addEventListener("paste", (event) => {
        this.handlePaste(event);
      });
    });
  }

  handleInput(input, index) {
    const value = input.value;
    if (value.length === 1) {
      if (index < this.otpInputTargets.length - 1) {
        this.otpInputTargets[index + 1].focus();
      } else {
        this.writeOTPValue();
      }
    }
  }

  handleBackspace(event, input, index) {
    if (event.keyCode === 8 && input.value.length === 0) {
      if (index > 0) {
        this.otpInputTargets[index - 1].focus();
        this.otpInputTargets[index - 1].value = "";
      }
    }
  }

   handlePaste(event) {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text/plain");
    const otpDigits = pastedData.replace(/\D/g, "").slice(0, 6).split("");

    otpDigits.forEach((digit, index) => {
      if (index < this.otpInputTargets.length) {
        this.otpInputTargets[index].value = digit;
      }
    });

    this.writeOTPValue();

    // Focus on the last digit
    const lastDigitIndex = Math.min(otpDigits.length - 1, this.otpInputTargets.length - 1);
    this.otpInputTargets[lastDigitIndex].focus();
  }

  writeOTPValue() {
    const otpValue = this.otpInputTargets.map((input) => input.value).join("");
    // Do something with the otpValue, e.g., assign it to a controller property
    this.otpValue = otpValue;
    this.hiddenOtpFieldTarget.value = otpValue;
  }

  get otpValue() {
    return this.data.get("otp-value");
  }

  set otpValue(value) {
    this.data.set("otp-value", value);
  }
}