import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const DEFAULT_SPACE_BETWEEN = 30
const DEFAULT_SLIDE_PER_VIEW = 1

export default class extends Controller {
    static targets = ["swiper", "wrapper", "nextPage", "previousPage", "slide"]
    static values = {
        autoplay: Boolean,
        centeredSlides: Boolean,
        autoheight: Boolean,
        slidesPerView: String,
        spaceBetween: Number,
        loop: Boolean
    }

    static swiper
    static activeIndex

    initialize() {
        this.activeIndex = 0;
        this.reRenderActiveTab()
    }

    connect() {
        console.log('Swiper Connected', this.slidesPerViewValue ?? DEFAULT_SLIDE_PER_VIEW, this, this.spaceBetweenValue, DEFAULT_SPACE_BETWEEN, this.spaceBetweenValue ?? DEFAULT_SPACE_BETWEEN);
        this.initSwiper()
        this.reRenderActiveTab()
    }

    initSwiper() {
        let classnames = '.swiper'
        if(this.hasSwiperTarget){
          classnames = '.' + Array.from(this.swiperTarget.classList).find(value => value.includes('swiper-id'))
        } 

        console.log('init swipper', Array.from(this.swiperTarget.classList).find(value => value.includes('swiper-id')));

        this.swiper = new Swiper(classnames, {
            modules: [Navigation, Pagination],
            autoHeight: this.autoheightValue,
            loop: this.loopValue,
            slidesPerView: this.slidesPerViewValue ?? DEFAULT_SLIDE_PER_VIEW,
            autoplay: this.autoplayValue,
            centeredSlides: this.centeredSlidesValue,
            grabCursor: true,
            spaceBetween: this.spaceBetweenValue ?? DEFAULT_SLIDE_PER_VIEW,
            activeIndex: 0,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            // breakpointsBase: 'container',
            // breakpoints: {
            //     480: {
            //         slidesPerView: 1,
            //         spaceBetween: 10,
            //     },
            //     768: {
            //         slidesPerView: 2,
            //         spaceBetween: 20,
            //     },
            //     1024: {
            //         slidesPerView: 3,
            //         spaceBetween: 30,
            //     },
            // }
        });

        this?.swiper?.on && this.swiper.on('slideChange', function () {
            this.activeIndex = this.swiper.activeIndex
            this.reRenderActiveTab()
        }.bind(this));
    }

    slideTo(index) {
        const element = event.target
        this.activeIndex = element?.dataset?.swiperIndex ?? 0

        this.swiper.slideTo(this.activeIndex, 300)

        this.reRenderActiveTab()
    }

    reRenderActiveTab() {
        this.slideTargets.forEach((slide, index) => {
            const currentSwiperIndex = slide?.dataset?.swiperIndex ?? 0
            if (this.activeIndex == currentSwiperIndex) {
                slide.classList.add("active-tab")
                slide.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center'
              });
            } else {
                slide.classList.remove("active-tab")
            }
        })
    }
}
