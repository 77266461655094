import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["badge", "list", "placeholder", "notification", "bell"]
  static values = {
    userId: String, // Current user ID
    userUnread: Number, // Unread count for the current user
    totalUnread: Number, // Unread count across all the user's accounts
  }

  connect() {
    if (this.hasUnread()) this.showUnreadBadge()
  }

  // Called when the notifications view opens
  open() {
    this.hideUnreadBadge()
    this.markAllAsRead()
  }

  hasUnread() {
    return !!this.userUnreadValue
  }

  showUnreadBadge() {
    if (!this.hasBadgeTarget) { return }
    this.badgeTarget.classList.remove("hidden")
    this.bellTarget.classList.add("animatecss", "animatecss-swing")

    this.updateTitleNotificationsCount()
  }

  hideUnreadBadge() {
    if (!this.hasBadgeTarget) { return }
    this.badgeTarget.classList.add("hidden")

    this.updateTitleNotificationsCount(true)
  }

  markAllAsRead() {
    // TODO: Implement marking all as read without WebSocket
    console.log("markAllAsRead: Implement this method without WebSocket")
    let ids = this.notificationTargets.map((target) => target.dataset.id)
    
    // For now, just update the UI
    this.userUnreadValue = 0
    this.totalUnreadValue -= ids.length
    
    // You might want to make an AJAX call here to update the server
  }

  markAsInteracted(event) {
    // TODO: Implement marking as interacted without WebSocket
    console.log("markAsInteracted: Implement this method without WebSocket")
    let id = event.currentTarget.dataset.id
    if (id == null) return
    
    // You might want to make an AJAX call here to update the server
    
    // Uncomment to visually mark notification as interacted
    // event.currentTarget.dataset.interactedAt = new Date()
  }

  updateTitleNotificationsCount(remove = false) {
    let title = document.title

    if (this.userUnreadValue == 0 || remove) {
      document.title = title.replace(/^\[\d+\]\s/, '');
    } else {
      document.title = `[${this.userUnreadValue}] ${title}`
    }
  }

  // Browser notifications
  checkPermissionAndNotify(data) {
    // Return if not supported
    if (!("Notification" in window)) return

    if (Notification.permission === "granted") {
      this.browserNotification(data)
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          this.browserNotification(data)
        }
      })
    }
  }

  browserNotification(data) {
    new Notification(data.title, data.options)
  }
}