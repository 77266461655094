import { Controller } from "@hotwired/stimulus"
// import TinyMDE from 'tiny-markdown-editor';
const TinyMDE = require('tiny-markdown-editor');

export default class extends Controller {
  connect() {    
    var editorID = this.element.querySelector('textarea').id;
    var tinyMDE = new TinyMDE.Editor({ textarea: editorID });
    var commandBar = new TinyMDE.CommandBar({
      element: document.getElementById("toolbar"),
      editor: tinyMDE,
    });
  }
}
