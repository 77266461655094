// clickable_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    console.log('Clickable connected');
  }

  click(event) {
    if (!event.target.closest('.clickable-ignore')) {
      window.location.href = this.urlValue
    }
  }
}