import { Controller } from "@hotwired/stimulus"
import { createCable }  from '@anycable/web'
import Cable from '../channels/cable';


export default class extends Controller {
  static targets = [ "points", "games", "sets", "setWrapper", "timer", "tiebreak", "isServe" ]
  static values = { 
    matchId: String,
    matchStatus: String
  }

  connect() {
    if (this.matchStatusValue === 'ongoing') {
      this.connectWebSocket();
    }
  }

  connectWebSocket() {
    this.channel = Cable.subscribeTo("MatchChannel", {
      match_id: this.matchIdValue
    });

    this.channel.on("message", (data) => {
      this.processEvent(data);
    });

    this.channel.on('connect', ev => {
        console.log("Connected to MatchChannel");
        this.channel.perform('get_state');
    })
  }

  processEvent(data) {
    console.log("Event", data);

    switch(data.action) {
      case 'update_score':
        console.log("Updating score");
        this.updateScores(data.scores);
        break;
      case 'match_state':
        console.log("Match state");
        this.updateScores(data.scores);
        this.startTimer(data.started_at);
        if (data.is_tiebreak_game === true) {
          this.showTiebreakGame();
        }
        break;
      case 'tiebreak_game':
        console.log("Tiebreak game");
        this.showTiebreakGame();
        break;
    }
  }

  updateScores(scores) {
    Object.entries(scores).forEach(([teamKey, scoreDetails]) => {
      this.updateScore("points", scoreDetails.id, scoreDetails.points);
      this.updateScore("games", scoreDetails.id, scoreDetails.current_game);
      this.updateSets(scoreDetails.id, scoreDetails.sets);
      this.updateIsServe(scoreDetails.id, scoreDetails.is_serve);
    });
  }

  updateScore(scoreType, teamId, score) {
    console.log('Updating score', scoreType, teamId, score);
    const scoreTargets = this[scoreType + "Targets"];
    const target = Array.from(scoreTargets).find(target => target.dataset.teamId === teamId);
    if (target) {
      target.textContent = score;
    }
  }

  updateSets(teamId, sets) {
    const setTargets = Array.from(this.setsTargets);
    const currentSetCount = setTargets.filter(target => target.dataset.teamId === teamId).length;
    const requiredSets = sets.length;

    if (currentSetCount < requiredSets) {
      for (let i = currentSetCount; i < requiredSets; i++) {
        const newSetDiv = document.createElement('div');
        newSetDiv.classList.add('relative', 'w-6', 'md:w-7', 'w-6', 'md:h-7', 'bg-gray-100', 'font-medium', 'flex', 'items-center', 'justify-center', 'leading-none', 'rounded');
        if (i + 1 === requiredSets) {
          newSetDiv.classList.add('bg-alto-300');
        }

        const setDiv = document.createElement('div');
        setDiv.setAttribute('data-match-target', 'sets');
        setDiv.setAttribute('data-set-number', (i + 1).toString());
        setDiv.setAttribute('data-team-id', teamId);
        setDiv.textContent = "0"; // Set the textContent to 0 for the new div
        newSetDiv.appendChild(setDiv);

        // Create a span for tiebreak score
        const tiebreakSpan = document.createElement('span');
        tiebreakSpan.setAttribute('data-match-target', 'tiebreak');
        tiebreakSpan.setAttribute('data-set-number', (i + 1).toString());
        tiebreakSpan.setAttribute('data-team-id', teamId);
        tiebreakSpan.classList.add('absolute', 'right-1', 'top-1', 'text-[8px]', 'font-medium');
        tiebreakSpan.textContent = ""; // Initial tiebreak score
        newSetDiv.appendChild(tiebreakSpan);

        // Find the correct setWrapperTarget based on teamId
        const setWrapperTarget = Array.from(this.setWrapperTargets).find(target => target.dataset.teamId === teamId);
        if (setWrapperTarget) {
          setWrapperTarget.appendChild(newSetDiv); // Append new set blocks within the correct setWrapperTarget
        }
      }
    }

    const lastSetNumber = Math.max(...sets.map(set => parseInt(set.set_number)));
    sets.forEach((set, index) => {
      const setTarget = setTargets.find(target => 
        target.dataset.teamId === teamId && target.dataset.setNumber === set.set_number.toString()
      );
      if (setTarget) {
        setTarget.textContent = set.games_won;
        if (set.set_won) {
          setTarget.classList.add("set-won");
        } else {
          setTarget.classList.remove("set-won");
        }
        // Update the background color and text color for the last set
        if (parseInt(set.set_number) === lastSetNumber) { // Check if it's the last set
          console.log(setTarget.parentElement);

          setTarget.parentElement.classList.add("bg-alto-500");
          setTarget.parentElement.classList.add("text-black");
          setTarget.parentElement.classList.remove("bg-gray-100");
        } else {
          // For non-current sets, check the highest value of games won and set the parentElement background
          // const highestGamesWon = Math.max(...sets.map(s => s.games_won));
          // if (set.games_won === highestGamesWon) {
          //   setTarget.parentElement.classList.add("bg-court-100");
          // } else {
          //   setTarget.parentElement.classList.add("bg-gray-100");
          // }
          setTarget.parentElement.classList.remove("bg-alto-500");
          setTarget.parentElement.classList.remove("text-black");
        }
        // Update tiebreak score if present
        const tiebreakSpan = setTarget.querySelector('[data-match-target="tiebreak"]');
        if (tiebreakSpan && set.tiebreak_score) {
          tiebreakSpan.textContent = set.tiebreak_score;
          // Check if the previous set has a tiebreak score and update it
          if (index > 0 && sets[index - 1].tiebreak_score) {
            const previousTiebreakSpan = setTargets[index - 1].querySelector('[data-match-target="tiebreak"]');
            if (previousTiebreakSpan) {
              previousTiebreakSpan.textContent = sets[index - 1].tiebreak_score;
              // Update the tiebreakTarget that matches the data.teamId
              const tiebreakTarget = Array.from(this.tiebreakTargets).find(target => target.dataset.teamId === teamId);
              if (tiebreakTarget) {
                tiebreakTarget.textContent = sets[index - 1].tiebreak_score;
              }
            }
          }
        }
      }
    });
  }

  updateIsServe(teamId, isServe) {
    const isServeTargets = this["isServeTargets"];
    const target = Array.from(isServeTargets).find(target => target.dataset.teamId === teamId);
    if (target) {
      if(isServe) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    }
  }

  showTiebreakGame() {
    if (this.tiebreakTarget) {
      const tiebreakSpan = this.tiebreakTarget.querySelector('span');
      if (tiebreakSpan) {
        tiebreakSpan.classList.remove("hidden");
      }
    }
  }

  disconnect() {
  }

  startTimer(startedAt = new Date()) {
    this.startTime = new Date(startedAt).getTime();
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  updateTimer() {
    const now = new Date().getTime();
    const timeElapsed = now - this.startTime;
    const hours = Math.floor((timeElapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeElapsed % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeElapsed % (1000 * 60)) / 1000);

    const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    ].join(':');

    this.timerTarget.textContent = formattedTime;
  }  

}
