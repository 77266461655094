import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["matchCode", "teamCard", "slide"]

    connect() {
        console.log('Connected');
        this.matchCodeTargets.forEach(target => {
            target.addEventListener("mouseenter", this.highlight.bind(this));
            target.addEventListener("mouseleave", this.unhighlight.bind(this));
        });
    }

    highlight(event) {
        const matchCode = event.target.dataset.nextMatch;
        console.log(matchCode);
        const targetElement = document.getElementById(`match-${matchCode}`);
        console.log(targetElement);
        if (targetElement) {
            targetElement.classList.add("highlight-card");
        }
    }

    unhighlight(event) {
        const matchCode = event.target.dataset.relatedMatch;
        const targetElement = document.getElementById(`match-${matchCode}`);
        if (targetElement) {
            targetElement.classList.remove("highlight-card");
        }
    }
}
