// OTP Controller
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "arrow"];
  static values = { open: { type: Boolean, default: false } };

    connect() {
        if (this.openValue) {
            this.contentTarget.classList.remove("hidden");
            this.arrowTarget.classList.add("rotate-90");
        } else {
            this.contentTarget.classList.add("hidden");
            this.arrowTarget.classList.remove("rotate-90");
        }
    }

    toggle() {
        this.contentTarget.classList.toggle("hidden");
        this.arrowTarget.classList.toggle("rotate-90");
    }
}